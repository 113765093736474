import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import { FaGamepad, FaUsers, FaMobileAlt, FaBan } from 'react-icons/fa'; // Import your icons here
import { MdOutlineReplay, MdOutlineHowToVote, MdForum } from "react-icons/md";
import { FaRankingStar } from "react-icons/fa6";
import Navbar from '../components/navbar'
import Feature from '../components/feature'

import Footer from '../components/footer'
import './home.css'

const Home = (props) => {

 



  return (
    <div className="home-container1">
      <Helmet>
        <title>iTichu</title>
        <meta name="description" content="Η Επόμενη Γενιά του Online Tichu" />
        <meta property="og:title" content="iTichu" />
        <meta property="og:description" content="Η Επόμενη Γενιά του Online Tichu" />
      </Helmet>
      <div className="home-hero">
        <header className="home-heading10">
         
          <Navbar></Navbar>
        </header>
        <div className="home-content10">
          <div className="home-content11">
            <h1 className="home-title1">
              iTichu, για τους λάτρεις του Tichu
            </h1>
            <span className="home-caption1">
              Μπείτε στη νέα γενιά του online Tichu και ανακαλύψτε όλες τις δυνατότητες!
            </span>
            <div className="home-hero-buttons1">
              <div className="home-ios-btn1">
                <img
                  alt="pastedImage"
                  src="/pastedimage-zmzg.svg"
                  className="home-apple1"
                />
                <span className="home-caption2">iOS App</span>
              </div>
              <div className="home-android-btn1">
                <img
                  alt="pastedImage"
                  src="/pastedimage-ld65.svg"
                  className="home-android1"
                />
                <span className="home-caption3">Android App</span>
              </div>
            </div>
          </div>
          <div className="home-images">
            <div className="home-column1">
              <img
                alt="pastedImage"
                src="/special_dogs.png"
                className="home-pasted-image1"
              />
            </div>
            <div className="home-column2">
              <img
                alt="pastedImage"
                src="/special_dragon.png"
                className="home-pasted-image2"
              />
              <img
                alt="pastedImage"
                src="/special_backside.png"
                className="home-pasted-image3"
              />
            </div>
            <div className="home-column3">
              <img
                alt="pastedImage"
                src="/special_phoenix.png"
                className="home-pasted-image4"
              />
              <img
                alt="pastedImage"
                src="/special_mahjong.png"
                className="home-pasted-image5"
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="home-video1">
        <div className="home-content12">
          <div className="home-header1">
            <h2 className="home-text10">
            Δίκαιο παιχνίδι για όλους
            </h2>
          </div>
          <div className="home-video-container">
            <video
              src="/video_loop.mp4"
              loop
              muted
              
              autoPlay
              className="home-video2"
            ></video>
            <div className="home-heading-container">
              <div className="home-heading11">
                <span className="home-text11">
                  Απολαύστε την ταχύτητα και ασφάλεια
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text12">
               Προσφέρουμε ταχύτητα και ασφάλεια υψηλού επιπέδου, εξασφαλίζοντας άμεσες αντιδράσεις και προστασία των δεδομένων σας. Με προηγμένα συστήματα κρυπτογράφησης και βελτιστοποίηση απόδοσης, απολαμβάνετε ομαλό και ασφαλές παιχνίδι σε κάθε σύνδεση.
                </span>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      <div className="home-stats">
        <div className="home-stat1">
          <span className="home-caption4">✔</span>
          <span className="home-description1">
            Εντελώς δωρεάν, για πάντα
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </div>
        <div className="home-stat2">
          <span className="home-caption5">X</span>
          <span className="home-description2">
          Δεν θα δεχτούμε δωρεές ή χορηγίες οποιουδήποτε είδους
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </div>
        <div className="home-stat3">
          <span className="home-caption6">1.000 +</span>
          <span className="home-description3">
            Αρχικός μας στόχος: 1.000 χρήστες
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </div>
      </div>
     
      <div className="home-features">
        <div className="home-header2">
          <div className="home-tag1">
            <span className="home-text26">Features</span>
          </div>
          <div className="home-heading13">
            <h2 className="home-text27">Όλα όσα προσφέρουμε</h2>
            <span className="home-text28">
              Μία συνοπτική λίστα με τις λειτουργίες και δυνατότητες:
            </span>
          </div>
        </div>
        <div className="home-feature-list">
          <Feature
        title="Online σε Πραγματικό Χρόνο"
        icon={<FaGamepad size={48} />} // Using a mobile icon
        description="Παίξτε με φίλους ή συμμετάσχετε σε παιχνίδια με παίκτες από όλο τον κόσμο σε πραγματικό χρόνο. Ζήστε την ένταση του Tichu οποιαδήποτε στιγμή, οπουδήποτε."
      />
      <Feature
        title="Δίκαιο Περιβάλλον Παιχνιδιού"
        icon={<FaUsers size={48} />} // Analytics icon
        description="Το αυτόματο σύστημα αποκλεισμού κατά της εξαπάτησης εξασφαλίζει δίκαιο παιχνίδι σε κάθε τραπέζι. Οι παίκτες που παραβιάζουν τους κανόνες αποκλείονται αυτόματα, εξασφαλίζοντας ποιοτική εμπειρία για όλους."
      />
      <Feature
        title="Έξυπνο Σύστημα Αντιστοίχισης"
        icon={<FaRankingStar size={48} />} // Virtual card icon
        description="Βρείτε αυτόματα παίκτες στο επίπεδό σας με το έξυπνο σύστημα αντιστοίχισης, εξασφαλίζοντας ισορροπημένη και ανταγωνιστική εμπειρία."
      />
      <Feature
        title="Υποστήριξη Πολλαπλών Πλατφόρμων"
        icon={<FaMobileAlt size={48} />} // Safe transactions icon
        description="Παίξτε Tichu σε οποιαδήποτε συσκευή—είτε στο smartphone, το tablet ή τον υπολογιστή σας. Η απρόσκοπτη υποστήριξη πολλαπλών πλατφορμών σας επιτρέπει να συνεχίζετε το παιχνίδι όπου κι αν βρίσκεστε."
      />
      <Feature
        title="Σύστημα Επαναλήψεων (Replay)"
        icon={<MdOutlineReplay size={48} />} // Milestones icon
        description="Διαθέτει σύστημα επαναλήψεων που σας επιτρέπει να παρακολουθείτε ξανά τις καλύτερες στιγμές του παιχνιδιού σας και τις στρατηγικές που χρησιμοποιήσατε."
      />
      <Feature
        title="Σύστημα Ψηφοφορίας"
        icon={<MdOutlineHowToVote size={48} />} // Trade icon
        description="Οι παίκτες μπορούν να συμμετέχουν σε ψηφοφορίες για να επιλέξουν τις αγαπημένες τους παραλλαγές παιχνιδιού ή να προτείνουν νέες λειτουργίες."
      />
      <Feature
        title="Φόρουμ Κοινότητας"
        icon={<MdForum size={48} />} // Wallet icon
        description="Συμμετάσχετε σε ένα φόρουμ κοινότητας για να συζητάτε στρατηγικές, να μοιράζεστε εμπειρίες και να συνδεθείτε με άλλους παίκτες."
      /> 
      <Feature
        title="Anti-Cheat"
        icon={<FaBan size={48} />} // Wallet icon
        description="Το παιχνίδι διαθέτει προηγμένο σύστημα ανίχνευσης απάτης, εξασφαλίζοντας ότι οι χρήστες δεν θα μπορούν να χρησιμοποιούν εξωτερικό λογισμικό για να παραποιήσουν τα παιχνίδια. Αυτό διασφαλίζει μια δίκαιη και ανταγωνιστική εμπειρία για όλους τους παίκτες."
      /> 
        </div>
      </div>
     
     
      <div className="home-faq">
        <div className="home-content19">
          <div className="home-header6">
            <div className="home-tag3">
              <span className="home-text77">
                <span>FAQ</span>
                <br></br>
              </span>
            </div>
            <div className="home-heading23">
              <h2 className="home-text80">Συχνές Ερωτήσεις</h2>
            </div>
          </div>
          <div className="home-rows">
            <div className="home-column4">
              <div className="Question">
                <span className="home-title2">
                  <b>Πώς μπορώ να παίξω;</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description4">
                Απλά δημιουργείτε έναν λογαριασμό, συνδέεστε και ξεκινάτε να παίζετε άμεσα! Το παιχνίδι παίζεται απευθείας από τον browser σας, χωρίς να χρειάζεται εγκατάσταση.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
              <div className="Question">
                <span className="home-title3">
                  <b>Πρέπει να πληρώσω ή υπάρχει κάποια συνδρομή;</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description5">
                  <span>
                  Όχι, είναι και θα παραμείνει για πάντα δωρεάν
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
              
                </span>
              </div>
              <div className="home-question3 Question">
                <span className="home-title4">
                <b>Υπάρχουν αγορές μέσα στο παιχνίδι;</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description6">
                Όχι, το παιχνίδι δεν περιλαμβάνει αγορές εντός παιχνιδιού. Όλες οι δυνατότητες είναι διαθέσιμες χωρίς κόστος
                
                </span>
              </div>
            </div>
            <div className="home-column5">
              <div className="home-question4 Question">
                <span className="home-title5">
                  
                  <b>Τότε τι κέρδος έχετε απο αυτό το πρότζεκτ;</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description7">
                Το παιχνίδι είναι δωρεάν γιατί θέλουμε να προσφέρουμε μια ευχάριστη εμπειρία σε όλους τους παίκτες, ανεξάρτητα από τις οικονομικές τους δυνατότητες. Πιστεύουμε ότι τα παιχνίδια πρέπει να είναι προσιτά σε όλους και να φέρνουν τους ανθρώπους κοντά, χωρίς περιορισμούς. Επίσης, η κοινότητα των παικτών μας είναι η μεγαλύτερη ανταμοιβή για εμάς και μας βοηθά να εξελισσόμαστε συνεχώς
                </span>
              </div>
              <div className="home-question5 Question">
                <span className="home-title6">
                  <b>Υπάρχει υποστήριξη για κινητές συσκευές;</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description8">
                Ναι, το παιχνίδι είναι πλήρως συμβατό με κινητές συσκευές. Μπορείτε να παίξετε από το κινητό ή το tablet σας μέσω του browser/app
                </span>
              </div>
              <div className="home-question6 Question">
                <span className="home-title7">
                  <b>Μπορώ να έχω πολλαπλούς λογαριασμούς;</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-description9">
                  <span>
                   Όχι, κάθε χρήστης έχει το δικαίωμα για έναν μόνο λογαριασμό
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-get-started2">
        <div className="home-content20">
          <div className="home-heading24">
            <h2 className="home-text87">Ξεκίνα!</h2>
            <span className="home-text88">
              Συνδέσου και παίξε Tichu online
             
            </span>
          </div>
          <div className="home-hero-buttons2">
            <div className="home-ios-btn2">
              <img
                alt="pastedImage"
                src="/pastedimage-zmzg.svg"
                className="home-apple2"
              />
              <span className="home-caption7">iOS App</span>
            </div>
            <div className="home-android-btn2">
              <img
                alt="pastedImage"
                src="/pastedimage-ld65.svg"
                className="home-android2"
              />
              <span className="home-caption8">Android App</span>
            </div>
          </div>
        </div>
      </div>
      
      <Footer></Footer>
    </div>
  )
}

export default Home
