import React from 'react'
import PropTypes from 'prop-types'
import './feature.css'

const Feature = (props) => {
  return (
    <div className={`feature-feature ${props.rootClassName}`}>
      <div className="feature-icon">{props.icon}</div>
      <div className="feature-content">
        <span className="feature-title">{props.title}</span>
        <span className="feature-description">{props.description}</span>
      </div>
    </div>
  )
}


Feature.propTypes = {
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element, // Expecting an icon component as a prop
}

export default Feature
