import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://formspree.io/f/xkgnwljy', { 
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, message }),
    });

    if (response.ok) {
      setSuccess(true);
      setEmail('');
      setMessage('');
    } else {
      setError(true);
    }
  };

  return (
    <div className="wrapper">
      <div className="intro-centered-box">
        <h3>Επικοινωνία</h3>
        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Ηλ. διεύθυνση"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <div className="input-group">
            <textarea
              placeholder="Μήνυμα"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <button type="submit" className="submit-button">
            Αποστολή
          </button>
          {success && <p className="success-message">Η αποστολή σας ολοκληρώθηκε!</p>}
          {error && <p className="error-message">Προέκυψε σφάλμα, παρακαλώ προσπαθήστε ξανά.</p>}
        </form>
      </div>
    </div>
  );
}

export default Contact;
