import React from 'react';
import './introduction.css';

const Introduction = () => {
  return (
    <div className="wrapper"> {/* Add wrapper div */}
      <div className="intro-centered-box">
        <h3>Σημαντική Ενημέρωση</h3>
        <div className="intro-scrollable-text">
          <p>Αυτό το πρότζεκτ προορίζεται για άτομα που λατρεύουν το αγαπημένο μας καρτοπαίχνιδο, το Tichu.</p>
          <p>Επι πάρα πολλά χρόνια υπήρχε ένα μονοπώλιο στον κλάδο του Online Tichu (Δεν αναφέρουμε όνομα), το οποίο δεν συντηρείται, δεν υπάρχουν έλεγχοι για απάτες, μεταφορές ELO μεταξύ λογαριασμών, χωρίς διαχειριστές, ένα άσχημο design και πολλά άλλα.</p>
          <p>Για αυτό τον λόγο δημιουργήσαμε το 'iTichu'.</p>
          <p>Ό,τι έκαναν αυτοί λάθος θα το κάνουμε εμείς σωστά.</p>
          <p>Η πλατφόρμα γενικά θα είναι community-driven, που σημαίνει ότι π.χ. εαν θελήσετε να προστεθούν ή/και να αφαιρεθούν λειτουργίες, θα υπάρχουν δημόσιες ψηφοφορίες στις οποίες μπορείτε να συμμετάσχετε.</p>
          <p>Θέλουμε να δημιουργήσουμε ενα φιλικό προς τον χρήστη περιβάλλον, χωρίς κλέψιμο, χωρίς απάτες και με έναν δίκαιο αλγόριθμο, δεν πρέπει να ξεχνάμε ότι παίζουμε Tichu για τη διασκέδαση.</p>
          <p>Κάθε Χρήστης θα έχει ένα behavior score (σκορ συμπεριφοράς), πράγμα που σημαίνει ότι αυτοί που έχουν ίδιο ELO αλλά διαφορετικό behavior score δεν θα παίζουν μαζί.</p>
          <p>Το behavior score πέφτει με αναφορές και ανεβαίνει με θετικές αξιολογήσεις. Παίζοντας συνεχόμενα παιχνίδια χωρίς ο Χρήστης να δέχεται αναφορές, βελτιώνει το σκορ του.</p>
          <p>Οι ρόλοι θα είναι οι εξής: Super Administrators - Administrators - Game Masters - Forum Moderators.</p>
          <p>Για να μην σας τα πολυλογώ θα έχει πάρα πολλές λειτουργίες οι οποίες θα ανακοινωθούν στο πρώτο release.</p>
          <p>Αυτή τη χρονική στιγμή (16/10/2024) το πρότζεκτ βρίσκεται στο 45-50% και ο εκτιμώμενος χρόνος παράδοσης είναι περίπου τέλη Νοεμβρίου - αρχές Δεκεμβριού (2024).</p>
          <p>Σας ζητούμε συγνώμη για την καθυστέρηση και κατανόηση επειδή ο χρόνος μας είναι περιορισμένος.</p>
        </div>
        <p>Εάν ενδιαφέρεστε να συνεισφέρετε σε αυτό το έργο, παρακαλούμε επικοινωνήστε μαζί μας.Θα χαρούμε να έχουμε νέα άτομα στην ομάδα μας!</p>
        <p>Στο κουμπί 'Είσοδος' μπορείτε να δείτε το landing page για να έχετε μια γενική εικόνα το πως θα είναι η τελική μορφή.</p>
        <div className="button-container">
          <a href='/contact'><button className="intro-button">Επικοινωνία</button></a>
           <a href='/homepage'> <button className="intro-button">Είσοδος</button></a>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
